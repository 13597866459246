.globalMetrics {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -20px;
}

.globalMetrics > div {
    margin: 0;
    flex-basis: calc(50% - 1rem);
    box-sizing: border-box;
}

.globalMetrics > div.total {
    margin-right: 1.5rem;
}

.globalMetrics .count {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
    color: rgba(51, 51, 51, 0.87);
}

@media (max-width: 599px) {
    .globalMetrics .count {
        font-size: 1.5rem;
    }
}

.globalMetrics .desc {
    font-size: .75rem;
    margin: 0;
    color: #999;
}