.dashboardCard .time-range .MuiInputBase-root {
    font-size: 0.75rem;
}

.dashboardCard .time-range .MuiInputBase-root::after,
.dashboardCard .time-range .MuiInputBase-root::before {
    border-bottom: 0 !important;
}

.dashboardCard .time-range .MuiSelect-select:focus {
    background-color: transparent !important;
}