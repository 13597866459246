body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDrawer-docked {
  border-right: 1px solid #e6e6e6;
  background: #f7f7f7;
  height: unset !important;
}

.MuiPaper-rounded {
  border-radius: 15px !important;
}

th.MuiTableCell-head:first-child {
  border-top-left-radius: 15px !important;
}

th.MuiTableCell-head:last-child {
  border-top-right-radius: 15px !important;
}

#main-content {
  padding-left: 2%;
  padding-right: 2%;
  max-width: calc(96% - 48px);
  padding-top: 8px !important;
}

@media (max-width: 599px) {
  #main-content {
    max-width: 96%;
  }
}

.resourceShowTitle {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

main {
  align-items: stretch;
}