#dashboard h2 {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 2px;
    color: rgba(53, 53, 53, 0.87);
    font-size: 1.25rem;
}

#dashboard .dashboardCard {
    margin: 20px;
}

.twoCards {
    display: flex;
    flex-wrap: wrap;
}

.twoCards .dashboardCard {
    flex: 1 1 40%;
}

@media (min-width: 1600px) {
    #dashboard {
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
}

#dashboard .MuiCardHeader-root,
#dashboard .MuiCardHeader-root .MuiTypography-colorTextSecondary {
    color: rgba(72, 72, 72, 0.87) !important;
}

#dashboard .MuiCardHeader-root .MuiSvgIcon-root {
    color: rgba(72, 72, 72, 0.87);
}